import axios from 'axios'
import $HiMessage from '@/components/hi-messages/HiMessage.js' // 导入提示框
import $hi from './hi.js'

const COMMON = {}

let baseUrl = '/api'
// if (process.env.NODE_ENV === "development"){
// 	// 开发环境
// 	baseUrl = "http://132.sms_admin.tt/api/"
// } else if (process.env.NODE_ENV === "production"){
// 	// 线上环境
// 	// baseUrl = "https://shiyi.e3o.cn/api/"
// 	baseUrl = "https://admin.sms.221.e3o.cn/api/"
// }

const _config = {
	baseURL: baseUrl,
	timeout: 60000, // 请求超时时间 毫秒
	headers: {
		'Content-Type': 'application/json;charset=UTF-8',
		'X-Requested-With': 'XMLHttpRequest',
		'token': $hi.getCache('token')
	}
}

// 创建一个 axios 实例
const service = axios.create(_config)

// 添加请求拦截器
service.interceptors.request.use(function(config) {
	// 在发送请求之前做些什么
	return config;
}, function(error) {
	// 对请求错误做些什么
	return Promise.reject(error);
});

// 添加响应拦截器
service.interceptors.response.use(function(response) {
	// console.log(response)
	const res = response.data
	if (response.status != 200) {
		console.log('错误！！')
		return $HiMessage({
			type: 'error',
			text: res.msg
		})
	}

	// 2xx 范围内的状态码都会触发该函数。
	// 对响应数据做点什么
	return res;
}, function(error) {
	// 超出 2xx 范围的状态码都会触发该函数。
	// 对响应错误做点什么
	return Promise.reject(error);
});

/* 
 * 路由请求封装
 */
COMMON.http = (url, params = {}, method = 'GET') => {
	const arr = {
		url: url,
		method: method,
	}
	if (method == 'GET' || method == 'get') {
		arr['params'] = params
	} else {
		arr['data'] = params
	}
	return new Promise((resolve, reject) => {
		service(arr)
			.then(res => {
				// console.log(res, '-------------')
				if (res.code == 200) {
					return resolve(res.data)
				}
				// 警告
				if (res.code == 202) {
					$HiMessage({
						type: 'warn',
						text: res.msg
					})
					return setTimeout(function() {
						resolve(res.data)
					})
				}
				// 未登录
				if (res.code == 401) {
					console.log('去登录')
					return $HiMessage({
						type: 'error',
						text: res.msg
					})
				}
				if (res.code == 404) {
					$hi.setHeimingdanIp()  // 缓存黑名单状态
					// location.href = "http://404.sms.221.e3o.cn"
					return
				}
				// 请求数据不存在
				return $HiMessage({
					type: 'error',
					text: res.msg
				})
				// return res
			})
			.catch(error => {
				reject(error)
			})
	})
}

// 
export default COMMON